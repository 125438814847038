import { reverse } from "named-urls";
import queryString from 'querystring';

const generate = (route, params = {}) => reverse(route, params);
const stringify = (data) => queryString.stringify(data);

const parseSearchString = (searchStr, returnKey = null) => {
    const parsed = queryString.parse(searchStr);

    let queryParams = {};
    Object.entries(parsed).forEach(([key, value]) => {
        key = key.replace(/\?/gi, '');
        queryParams[key] = value;
    });

    if (!returnKey) {
        return queryParams;
    }

    if (returnKey in queryParams) {
        return queryParams[returnKey];
    }

    return {};
};

export const UtilUrl = {
    generate,
    parseSearchString,
    stringify
};