import { withRouter } from 'react-router';
import axios from 'axios';
import i18next from 'i18next';
import configureStore from '../store/store';
import { UtilApi } from '../utils/api.util';
import { UtilUrl } from '../utils/url.util';
import routes from '../routing/routes';

let authToken = null;
const setToken = (token) => {
    authToken = token;
};

let sessionId = null;
const setSessionId = (id) => {
    sessionId = id;
};

const state = configureStore().getState();

const client = axios.create({
    // baseURL: process.env.REACT_APP_API_HOST + '/' + process.env.REACT_APP_API_VERSION + '/',
    baseURL: process.env.REACT_APP_API_HOST.trim() + '/',
    headers: {
        'Content-Type': 'application/json',
        'X-Session': state.settings.sessionId
    }
});

const get = (url, params) => {
    return request({
        url: url,
        method: 'GET',
        params: params,
        headers: {}
    });
};

const remove = (url, params) => {
    // console.log(url);
    return request({
        url: url,
        method: 'DELETE',
        params: params,
        headers: {}
    });
};

const post = (url, data, headers = {}) => {
    // console.log(data);
    return request({
        url: url,
        method: 'POST',
        data: data,
        headers: headers
    });
};

const request = (requestOptions) => {
    UtilApi.cancelRequest(requestOptions.url);
    UtilApi.addCancelToken(requestOptions.url)

    if (!authToken) {
        authToken = state.patient ? state.patient.access_key : null;
    }

    delete client.defaults.headers.common['Authorization'];
    if (authToken) {
        client.defaults.headers.common['Authorization'] = authToken;
    }

    if (!sessionId) {
        sessionId = state.settings.sessionId ? state.settings.sessionId : null;
    }

    delete client.defaults.headers.common['X-Session'];
    if (sessionId) {
        client.defaults.headers.common['X-Session'] = sessionId;
    }

    if (requestOptions['headers']['Content-Type']) {
        client.defaults.headers.common['Content-Type'] = requestOptions['headers']['Content-Type'];
    }

    let apiVersion = 1;
    if ('data' in requestOptions) {
        if (!requestOptions.data) requestOptions.data = {};

        requestOptions.data.lng = i18next.language;

        if (requestOptions.data.apiVersion) apiVersion = requestOptions.data.apiVersion;
    }

    if ('params' in requestOptions) {
        if (!requestOptions.params) requestOptions.params = {};

        requestOptions.params.lng = i18next.language;

        if (requestOptions.params.apiVersion) apiVersion = requestOptions.params.apiVersion;
    }

    client.defaults.baseURL = process.env.REACT_APP_API_HOST.trim() + '/';
    if (apiVersion === 2) {
        client.defaults.baseURL = process.env.REACT_APP_API_HOST_2.trim() + '/';
    }
    client.defaults.cancelToken = UtilApi.getCancelToken(requestOptions.url);

    // console.log(requestOptions);

    return client(requestOptions)
        .then(response => {
            // console.log(response.status, response.statusText, ':', response.data.status, response.data.data);
            return response.data;
        }).catch(error => {
            if (axios.isCancel(error)) {
                // console.log('Request aborted: ' + error);
                return 'canceled';
            } else {
                const { status, statusText } = error.response;

                // console.log(status);
                if (status === 401) {
                    // console.log(error.response);
                    // TODO: Show unauthorized message
                    // TODO: Check if token is valid; if not redirect to login
                    window.location = UtilUrl.generate(routes.auth.login, { path: error.response.data.message.login });
                    // return error.response.data;
                } else if (status === 403) {
                    throw error;
                    //window.location = UtilUrl.generate(routes.group.decisionTree);
                } else if (status === 404) {
                    window.location = UtilUrl.generate(routes.notfound);
                } else if (status === 500) {
                    // LocalStorageService.store('FlashMessage', {type: 'error', data: 'An error occured'});
                    // window.location = routes.error;
                } else {
                    console.error(status, statusText, error)
                }
            }
        }).then((response) => {
            UtilApi.removeCancelToken(requestOptions.url);

            return response;
        });
};

const isSuccess = (response) => response.status && response.status === 'success';

export const API = withRouter({ get, post, remove, isSuccess, setToken, setSessionId });