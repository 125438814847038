import React from 'react';
import { t } from 'i18next';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from 'reactstrap';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import { compose } from 'recompose';
import { bindActionCreators } from 'redux';
import * as actions from '../../store/actions';

class DropdownLanguageSelect extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dropdownOpen: false
        }
    }

    render() {
        const { language } = this.props.settings;

        return (
            <UncontrolledDropdown nav inNavbar className="dropdown-list">
                <DropdownToggle nav className="dropdown-toggle-nocaret">
                    <img src={'/images/languages/' + (language === 'nl' ? 'be' : language) + '.png'} alt={t('language:' + language)} width="20px" className="mr-1" />
                    <span>{t('language:' + language)}</span>
                </DropdownToggle>

                {/* START Dropdown menu */}
                <DropdownMenu right className="dropdown-menu-right animated fadeIn">
                    <DropdownItem>
                        { /* START list group */}
                        <ListGroup>
                            {(this.props.group.languages ? this.props.group.languages : ['nl', 'fr', 'en']).map(lng => (
                                <ListGroupItem action key={lng} onClick={() => this.changeLanguage(lng)}>
                                    <div className="media">
                                        <div className="align-self-start mr-2">
                                            <img src={'/images/languages/' + (lng === 'nl' ? 'be' : lng) + '.png'} alt={t('language:' + lng)} width="20px" className="mr-1" />
                                        </div>
                                        <div className="media-body">
                                            <p className="m-0">
                                                {t('language:' + lng)}
                                            </p>
                                        </div>
                                    </div>
                                </ListGroupItem>
                            ))}
                        </ListGroup>
                        {/* END list group */}
                    </DropdownItem>
                </DropdownMenu>
                {/* END Dropdown menu */}
            </UncontrolledDropdown>
        );
    }

    toggle = () => this.setState({ dropdownOpen: !this.state.dropdownOpen });

    changeLanguage = lng => {
        this.props.i18n.changeLanguage(lng);
        this.props.actions.changeSetting('language', lng);
    }
};

const mapStateToProps = state => ({ group: state.group ? state.group : {}, settings: state.settings })
const mapDispatchToProps = dispatch => ({ actions: bindActionCreators(actions, dispatch) });

export default compose(connect(mapStateToProps, mapDispatchToProps), withNamespaces())(DropdownLanguageSelect);